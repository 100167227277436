<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title">
        <span class="title font-weight-bold">
          {{ i18n.temperature }}
        </span>
        <span v-bind:style="isOperatingCorrectly" class="ml-5">{{ elementObj.currentValue }}℃ (now)</span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="javascript:;" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div v-if="this.controllerType !== '제닉스' " class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.target }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="tempValue" min="0" max="100" size="sm" @change="conveyData('tempValue')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType == '엠코피아' " class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.management }}</span>
        </label>
        <b-card no-body class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.maxDay}}</div>
            <b-form-spinbutton v-model="tempMax" min="0" max="100" size="sm" @change="conveyData('tempMax')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.minNight}}</div>
            <b-form-spinbutton v-model="tempMin" min="0" max="100" size="sm" @change="conveyData('tempMin')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{tempnMax}}℃</div>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{tempnMin}}℃</div>
          </div>
        </b-card>
      </div>

      <div v-if="this.controllerType === '제닉스' " class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center pb-2" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.targetDay }}</span>
        </label>  
        <b-card no-body class="col-12 col-lg-10 d-flex flex-wrap flex-row pt-2 pb-4 mb-2">
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.target}}</div>
            <b-form-spinbutton v-model="tempValues[0]" min="0" max="40" size="sm" @change="conveyData('tempValues[0]')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.diff}}</div>
            <b-form-spinbutton v-model="tempDiffs[0]" min="0" max="20" size="sm" step="0.1" @change="conveyData('tempDiffs[0]')"></b-form-spinbutton>
          </div>
        </b-card>
        <label class="col-form-label col-12 col-lg-2 align-self-center pb-2" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.targetNight }}</span>
        </label>  
        <b-card no-body class="col-12 col-lg-10 d-flex flex-wrap flex-row pt-2 pb-4 mb-2">
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.target}}</div>
            <b-form-spinbutton v-model="tempValues[1]" min="0" max="40" size="sm" @change="conveyData('tempValues[1]')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.diff}}</div>
            <b-form-spinbutton v-model="tempDiffs[1]" min="0" max="20" size="sm" step="0.1" @change="conveyData('tempDiffs[1]')"></b-form-spinbutton>
          </div>
        </b-card>
      </div>

      <div v-if="this.controllerType !== '제닉스' " class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.abnormalalert }}</span>
        </label>
        <b-card no-body class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.maxDay}}</div>
            <b-form-spinbutton v-model="tempLimithigh" min="0" max="100" size="sm" @change="conveyData('tempLimithigh')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.minNight}}</div>
            <b-form-spinbutton v-model="tempLimitlow" min="0" max="100" size="sm" @change="conveyData('tempLimitlow')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{tempnLimithigh}}℃</div>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{tempnLimitlow}}℃</div>
          </div>
        </b-card>
      </div>
      <!--end::form-group-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { syncObj3 } from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting1",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  watch: {
    effectValue(){
      if(this.elementObj) syncObj3(this.elementObj, this);      
    }
  },
  data() {
    return {
      temp: '',
      tempValue : 0,
      tempValues : [],      
      tempDiffs : [],
      tempMin : 0,
      tempMax : 0,
      tempnMin : 0,
      tempnMax : 0,
      tempLimithigh : 0,
      tempLimitlow : 0,
      tempnLimithigh : 0,
      tempnLimitlow : 0,
      i18n: {
        management: i18n.t('cultivationEnvControlSetting.management'),
        abnormalalert: i18n.t('cultivationEnvControlSetting.abnormalalert'),
        diff: i18n.t('cultivationEnvControlSetting.diff'),
        temperature: i18n.t('cultivationEnvControlSetting.temperature'),
        target: i18n.t('cultivationEnvControlSetting.target'),
        targetDay: i18n.t('cultivationEnvControlSetting.targetDay'),
        targetNight: i18n.t('cultivationEnvControlSetting.targetNight'),
        min: i18n.t('cultivationEnvControlSetting.min'),
        max: i18n.t('cultivationEnvControlSetting.max'),
        limitHigh: i18n.t('cultivationEnvControlSetting.limitHigh'),
        maxDay: i18n.t('cultivationEnvControlSetting.maxDay'),
        minNight: i18n.t('cultivationEnvControlSetting.minNight')
      }
    };
  },
  computed: {
    isOperatingCorrectly() {
      if (this.elementObj.currentValue > this.tempMax || this.elementObj.currentValue < this.tempMin) {
        return {
          color: 'red'
        }
      }

      return {
        color: 'green'
      }
    },
  },
  methods: {
    conveyData(key){
      this.mutateValue('temperature', key, this[key]);
      var nkey = "";
      
      if (key == "tempMax"){
        nkey =  "tempnMax";
        this[nkey] = this[key]-3
      } else if (key == "tempMin") {
        nkey =  "tempnMin";
        this[nkey] = this[key]-5
      } else if (key == "tempLimithigh"){
        nkey =  "tempnLimithigh";
        this[nkey] = this[key]-3
      } else if (key == "tempLimitlow"){
        nkey =  "tempnLimitlow";
        this[nkey] = this[key]-5
      } else if (key == "tempValues[0]"){
        nkey = "tempValues"        
        this[nkey][0] = this.tempValues[0]
      } else if (key == "tempValues[1]"){
        nkey = "tempValues"        
        this[nkey][1] = this.tempValues[1]
      } else if (key == "tempDiffs[0]"){
        nkey = "tempDiffs"        
        this[nkey][0] = this.tempDiffs[0]
      } else if (key == "tempDiffs[1]"){
        nkey = "tempDiffs"        
        this[nkey][1] = this.tempDiffs[1]
      }
      this.mutateValue('temperature', nkey, this[nkey]);
    }
  }
};
</script>
