<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start">
        <span class="title font-weight-bold">
          {{ i18n.EC }}
        </span>
        <span v-bind:style="isOperatingCorrectly" class="ml-5">{{ elementObj.currentValue }}dS/m (now)</span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="#" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.target }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="ecValue" min="0" max="3.5" size="sm" step="0.01" @change="conveyData('ecValue')"></b-form-spinbutton>
        </div>
      </div>

      <div v-if="this.controllerType == '엠코피아'" class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.management }}</span>
        </label>
        <div class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.max2}}</div>
            <b-form-spinbutton v-model="ecMax" min="0" max="3.5" size="sm" step="0.01" @change="conveyData('ecMax')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.min2}}</div>
            <b-form-spinbutton v-model="ecMin" min="0" max="3.5" size="sm" step="0.01" @change="conveyData('ecMin')"></b-form-spinbutton>
          </div>
        </div>
      </div>

      <div v-if="this.controllerType != '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.abnormalalert }}</span>
        </label>
        <div class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.max2}}</div>
            <b-form-spinbutton v-model="ecLimithigh" min="0" max="3.5" size="sm" step="0.01" @change="conveyData('ecLimithigh')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.min2}}</div>
            <b-form-spinbutton v-model="ecLimitlow" min="0" max="3.5" size="sm" step="0.01" @change="conveyData('ecLimitlow')"></b-form-spinbutton>
          </div>
        </div>
      </div>
      <div v-if="this.controllerType == '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.diff }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="ecDiff" min="0" max="3.5" size="sm" step="0.01" @change="conveyData('ecDiff')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType !== '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.interval }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-02" v-model="ecInterval" min="0" max="10000" size="sm" step="1" @change="conveyData('ecInterval')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType !== '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.duration }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-02" v-model="ecDuration" min="0" max="10000" size="sm" step="1" @change="conveyData('ecDuration')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <div v-if="this.controllerType != '코모로' && this.controllerType != '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.repeatCount }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-02" v-model="ecCount" min="0" max="10000" size="sm" step="1" @change="conveyData('ecCount')"></b-form-spinbutton>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import {syncObj3} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting5",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  watch: {
    effectValue(){
      if(this.elementObj) syncObj3(this.elementObj, this);
    }
  },
  data() {
    return {
      ecValue : 0,
      ecDiff : 0,
      ecLimithigh : 0,
      ecLimitlow : 0,
      ecInterval : 0,
      ecDuration : 0,
      ecMin: 0,
      ecMax: 0,
      ecCount: 0,
      i18n: {
        management: i18n.t('cultivationEnvControlSetting.management'),
        abnormalalert: i18n.t('cultivationEnvControlSetting.abnormalalert'),
        diff: i18n.t('cultivationEnvControlSetting.diff'),
        EC: i18n.t('cultivationEnvControlSetting.EC'),
        target: i18n.t('cultivationEnvControlSetting.target'),
        min: i18n.t('cultivationEnvControlSetting.min'),
        max: i18n.t('cultivationEnvControlSetting.max'),
        limitHigh: i18n.t('cultivationEnvControlSetting.limitHigh'),
        interval: i18n.t('cultivationEnvControlSetting.interval'),
        duration: i18n.t('cultivationEnvControlSetting.duration'),
        repeatCount: i18n.t('cultivationEnvControlSetting.repeatCount'),
        min2: i18n.t('cultivationEnvControlSetting.min2'),
        max2: i18n.t('cultivationEnvControlSetting.max2'),
      }
    };
  },
  computed: {
    isOperatingCorrectly() {
      if (this.elementObj.currentValue > this.ecMax || this.elementObj.currentValue < this.ecMin) {
        return {
          color: 'red'
        }
      }

      return {
        color: 'green'
      }
    }
  },
  methods: {
    conveyData(key){
      this.mutateValue('ec', key, this[key]);
    }
  }
};
</script>
