<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{ i18n.light }}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="#" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.startTime }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-timepicker v-if="this.controllerType == '제닉스'" id="timepicker2" v-model="ledStarttimeHm" size="sm" locale="en-us" class="mb-2 genix" minutes-step="60" @input="conveyTime"></b-form-timepicker>
          <b-form-timepicker v-else v-model="ledStarttimeHm" size="sm" locale="en-us" class="mb-2" @input="conveyTime"></b-form-timepicker>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-12">
          <span class="font-weight-normal">{{ i18n.operationTime }} : {{ledDurationHh}}{{i18n.hour}}</span>
        </label>
        <div class="col-12">
          <template>
            <div class="custom-range-slider">
              <b-form-input id="range-2" v-model="ledDurationHh" type="range" min="0" max="24" step="1" @change="conveyData('ledDurationHh')"></b-form-input>
              <div class=" d-flex justify-content-between">
                <span class="text">0</span>
                <span class="text">3</span>
                <span class="text">6</span>
                <span class="text">9</span>
                <span class="text">12</span>
                <span class="text">15</span>
                <span class="text">18</span>
                <span class="text">21</span>
                <span class="text">24</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!--end::form-group-->
      <!-- <div> 주간: {{ ledStarttimeHm }}~{{ ledEndtimeHm }}</div> -->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting6",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  watch: {
    effectValue(){
      if(this.elementObj) {
        this.ledDurationHh = this.elementObj.ledDurationHh
        if(this.elementObj.ledStarttimeHh !== undefined || this.elementObj.ledStarttimeMm !== undefined) {
          this.ledStarttimeHm = [(this.elementObj.ledStarttimeHh || '00'), (this.elementObj.ledStarttimeMm || '00'), '00'].join(':');
        }else{
          this.ledStarttimeHm = '';
        }
      }
    }
  },
  data() {
    return {
      ledStarttimeHm : '',
      ledDurationHh : 0,
      // ledEndtimeHm: '',
      i18n: {
        light: i18n.t('cultivationEnvControlSetting.light'),
        startTime: i18n.t('cultivationEnvControlSetting.startTime'),
        operationTime: i18n.t('cultivationEnvControlSetting.operationTime'),
        hour: i18n.t('cultivationEnvControlSetting.hour')
      }
    }
  },
  methods: {
    conveyTime(){
      if(this.ledStarttimeHm){
        const starttimeList = this.ledStarttimeHm.split(':');
        if(starttimeList && starttimeList.length === 3) {
          this.mutateValue('led', 'ledStarttimeHh', starttimeList[0]);
          this.mutateValue('led', 'ledStarttimeMm', starttimeList[1]);
        }
      }
    },
    conveyData(key){
      this.mutateValue('led', key, this[key]);
    },
    // timeCalc() {
    //   if(this.ledStarttimeHm){
    //     const starttimeList = this.ledStarttimeHm.split(':');
    //     const endtime = parseInt(starttimeList[0]) + parseInt(this.ledDurationHh);
    //     return {
    //       start: starttimeList[0] + ':' + starttimeList[1],
    //       end: endtime + ':' + starttimeList[1]
    //     };
    //   }
    // }
  }
};
</script>