<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{ elementObj.name }}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="#" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-6">
          <span class="font-weight-normal">{{ i18n.power }}</span>
        </label>
        <div class="col-6 d-flex justify-content-end">
          <b-form-checkbox v-model="isPumpUsed" name="check-button" switch @change="conveyPumpUse"></b-form-checkbox>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="isPumpUsed && this.controllerType !== '제닉스'" class="form-group row">
        <label class="col-form-label col-12">
          <span class="font-weight-normal">{{ i18n.maintenenceTime }}</span>
        </label>
        <div class="col-12">
          <b-form-group>
            <b-form-radio v-model="tempState" value=1 @change="conveyMaintenance">
              <span class="font-size-sm">{{ i18n.alwaysPerHour }}</span>
            </b-form-radio>
            <b-form-radio v-if="this.controllerType !== '큐센텍' && this.controllerType !== '제닉스'" v-model="tempState" value=2 @change="conveyMaintenance">
              <span class="font-size-sm">{{ i18n.halfAnHourPerHour }}</span>
            </b-form-radio>
          </b-form-group>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="isPumpUsed && this.controllerType === '큐센텍'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.startTime }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-timepicker v-if="this.controllerType == '제닉스'" v-model="mainpumpStarttimeHm" size="sm" locale="en-us" class="mb-2 genix" minutes-step="60" @input="conveyTime"></b-form-timepicker>
          <b-form-timepicker v-else v-model="mainpumpStarttimeHm" size="sm" locale="en-us" class="mb-2" @input="conveyTime"></b-form-timepicker>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="isPumpUsed && this.controllerType === '큐센텍'" class="form-group row">
        <label class="col-form-label col-12">
          <span class="font-weight-normal">{{ i18n.operationTime }} : {{ parseInt(durationtimeHh) }}시간</span>
        </label>
        <div class="col-12">
          <template>
            <div class="custom-range-slider">
              <b-form-input id="range-2" v-model="mainpumpDurationHh" type="range" min="0" max="24" step="1" @change="conveyDurationHhData('mainpumpDurationHh')"></b-form-input>
              <div class=" d-flex justify-content-between">
                <span class="text">0</span>
                <span class="text">3</span>
                <span class="text">6</span>
                <span class="text">9</span>
                <span class="text">12</span>
                <span class="text">15</span>
                <span class="text">18</span>
                <span class="text">21</span>
                <span class="text">24</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="isPumpUsed && (this.controllerType === '큐센텍')" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.interval }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-02" v-model="mainpumpInterval" min="0" max="100" size="sm" step="1" @change="conveyIntervalData"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="isPumpUsed && (this.controllerType === '큐센텍')" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.duration }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-02" v-model="mainpumpDuration" min="0" max="100" size="sm" step="1" @change="conveyDurationData"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting8",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  data() {
    return {
      mainpumpStarttimeHm : '',
      mainpumpDurationHh : '0',
      statusInterval: null,
      i18n: {
        pump: i18n.t('cultivationEnvControlSetting.pump'),
        power: i18n.t('cultivationEnvControlSetting.power'),
        maintenenceTime: i18n.t('cultivationEnvControlSetting.maintenenceTime'),
        alwaysPerHour: i18n.t('cultivationEnvControlSetting.alwaysPerHour'),
        halfAnHourPerHour: i18n.t('cultivationEnvControlSetting.halfAnHourPerHour'),
        startTime: i18n.t('cultivationEnvControlSetting.startTime'),
        interval: i18n.t('cultivationEnvControlSetting.interval'),
        operationTime: i18n.t('cultivationEnvControlSetting.operationTime'),
        duration: i18n.t('cultivationEnvControlSetting.duration')
      }
    };
  },
  watch: {
    mainpumpStarttimeHm() {
      this.mainpumpStarttimeHm = this.mainpumpStarttimeHm.split(':').slice(0,2).join(':');
    },
  },
  computed: {
    isPumpUsed: {
      get() {
        return this.tempState === 0 ? false : true
      },
      set() {}
    },
    tempState: {
      get() {
        return this.elementObj.state
      },
      set() {}
    },
    durationtimeHh: {
      get() {
        return this.elementObj.durationtimeHh;
      },
      set() {}
    },
    mainpumpDuration: {
      get() {
        return this.elementObj.duration == 'undefined'? 0: Number(this.elementObj.duration);
      },
      set() {}
    },
    mainpumpInterval: {
      get() {
        return this.elementObj.interval == 'undefined'? 0: Number(this.elementObj.interval);
      },
      set() {}
    }
  },
  mounted() {
    setTimeout(this.initPump,1000);
  },
  methods: {
    initPump() {
      if(this.elementObj) {
        if (this.elementObj.durationtimeHh && Number(this.elementObj.durationtimeHh))
          this.mainpumpDurationHh = this.elementObj.durationtimeHh
        if(this.elementObj.starttimeHh !== undefined || this.elementObj.starttimeMm !== undefined) {
          this.mainpumpStarttimeHm = [(this.elementObj.starttimeHh || '00'), (this.elementObj.starttimeMm || '00'), '00'].join(':');
        }else{
          this.mainpumpStarttimeHm = '';
        }
      }
    },
    conveyPumpUse(value) {
      const newTempValue = {
        ...this.elementObj,
        state: value ? 1 : 0
      }
      this.mutateValue('pump', this.elementObj.key, newTempValue);
    },
    conveyMaintenance(value){
      const newTempValue = {
        ...this.elementObj,
        state: Number(value)
      }
      this.mutateValue('pump', this.elementObj.key, newTempValue);
    },
    conveyTime(){
      if(this.mainpumpStarttimeHm){
        const starttimeList = this.mainpumpStarttimeHm.split(':');
        if(starttimeList && starttimeList.length === 3) {
          const newTempValue = {
            ...this.elementObj,
            starttimeHh: starttimeList[0],
            starttimeMm: starttimeList[1]
          }
          this.mutateValue('pump', this.elementObj.key, newTempValue);
        }
      }
    },
    conveyDurationHhData(key){
      if (key && this.mainpumpStarttimeHm) {
        if (this[key].length === 1)
          this[key] = `0${this[key]}`
        const newTempValue = {
          ...this.elementObj,
          durationtimeHh: this[key],
          durationtimeMm: '00'
        }
        this.mutateValue('pump', this.elementObj.key, newTempValue);
      }
    },
    conveyDurationData(value){
      if (value && this.mainpumpStarttimeHm) {
        const newTempValue = {
          ...this.elementObj,
          duration: value,
        }
        this.mutateValue('pump', this.elementObj.key, newTempValue);
      }
    },
    conveyIntervalData(value){
      if (value && this.mainpumpStarttimeHm) {
        const newTempValue = {
          ...this.elementObj,
          interval: value,
        }
        this.mutateValue('pump', this.elementObj.key, newTempValue);
      }
    }
  }
};
</script>
