<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start">
        <span class="title font-weight-bold">
          {{ i18n.pH }}
        </span>
        <span v-bind:style="isOperatingCorrectly" class="ml-5">{{ elementObj.currentValue }}pH (now)</span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="#" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.target }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-01" v-model="phValue" min="0" max="10" size="sm" step="0.01" @change="conveyData('phValue')"></b-form-spinbutton>
        </div>
      </div>

      <div v-if="this.controllerType == '엠코피아'" class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.management }}</span>
        </label>
        <div class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.max2}}</div>
            <b-form-spinbutton v-model="phMax" min="0" max="10" size="sm" step="0.01" @change="conveyData('phMax')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.min2}}</div>
            <b-form-spinbutton v-model="phMin" min="0" max="10" size="sm" step="0.01" @change="conveyData('phMin')"></b-form-spinbutton>
          </div>
        </div>
      </div>

      <div v-if="this.controllerType != '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.abnormalalert }}</span>
        </label>
        <div class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.max2}}</div>
            <b-form-spinbutton v-model="phLimithigh" min="0" max="10" size="sm" step="0.01" @change="conveyData('phLimithigh')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.min2}}</div>
            <b-form-spinbutton v-model="phLimitlow" min="0" max="10" size="sm" step="0.01" @change="conveyData('phLimitlow')"></b-form-spinbutton>
          </div>
        </div>
      </div>

      <div v-if="this.controllerType == '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.diff }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="phDiff" min="0" max="10" size="sm" step="0.01" @change="conveyData('phDiff')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType !== '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.interval }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-02" v-model="phInterval" min="0" max="10000" size="sm" step="1" @change="conveyData('phInterval')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType !== '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.duration }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-02" v-model="phDuration" min="0" max="10000" size="sm" step="1" @change="conveyData('phDuration')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <div v-if="this.controllerType != '코모로' && this.controllerType != '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.repeatCount }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-02" v-model="phCount" min="0" max="10000" size="sm" step="1" @change="conveyData('phCount')"></b-form-spinbutton>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import {syncObj3} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting4",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  watch: {
    effectValue(){
      if(this.elementObj) syncObj3(this.elementObj, this);
    }
  },
  data() {
    return {
      phValue : 0,
      phDiff: 0,
      phLimithigh : 0,
      phLimitlow : 0,
      phInterval : 0,
      phDuration : 0,
      phMin : 0,
      phMax : 0,
      phCount : 0,
      i18n: {
        management: i18n.t('cultivationEnvControlSetting.management'),
        abnormalalert: i18n.t('cultivationEnvControlSetting.abnormalalert'),
        diff: i18n.t('cultivationEnvControlSetting.diff'),
        pH: i18n.t('cultivationEnvControlSetting.pH'),
        target: i18n.t('cultivationEnvControlSetting.target'),
        min: i18n.t('cultivationEnvControlSetting.min'),
        max: i18n.t('cultivationEnvControlSetting.max'),
        limitLow: i18n.t('cultivationEnvControlSetting.limitLow'),
        interval: i18n.t('cultivationEnvControlSetting.interval'),
        duration: i18n.t('cultivationEnvControlSetting.duration'),
        repeatCount: i18n.t('cultivationEnvControlSetting.repeatCount'),
        min2: i18n.t('cultivationEnvControlSetting.min2'),
        max2: i18n.t('cultivationEnvControlSetting.max2'),
      }
    };
  },
  computed: {
    isOperatingCorrectly() {
      if (this.elementObj.currentValue > this.phMax || this.elementObj.currentValue < this.phMin) {
        return {
          color: 'red'
        }
      }

      return {
        color: 'green'
      }
    }
  },
  methods: {
    conveyData(key){
      this.mutateValue('ph', key, this[key]);
    }
  }
};
</script>
