<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start">
        <span class="title font-weight-bold">
          {{ i18n.CO2 }}
        </span>
        <span v-bind:style="isOperatingCorrectly" class="ml-5">{{ elementObj.currentValue }}ppm (now)</span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="#" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.target }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="co2Value" min="0" max="1500" size="sm" step="10" @change="conveyData('co2Value')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <div v-if="this.controllerType == '엠코피아'" class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.management }}</span>
        </label>
        <div class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{ i18n.max2 }}</div>
            <b-form-spinbutton v-model="co2Max" min="0" max="1500" size="sm" step="10" @change="conveyData('co2Max')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{ i18n.min2 }}</div>
            <b-form-spinbutton v-model="co2Min" min="0" max="1500" size="sm" step="10" @change="conveyData('co2Min')"></b-form-spinbutton>
          </div>
        </div>
      </div>

      <div v-if="this.controllerType !== '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.abnormalalert }}</span>
        </label>
        <div class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{ i18n.max2 }}</div>
            <b-form-spinbutton v-model="co2Limithigh" min="0" max="1500" size="sm" step="10" @change="conveyData('co2Limithigh')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{ i18n.min2 }}</div>
            <b-form-spinbutton v-model="co2Limitlow" min="0" max="1500" size="sm" step="10" @change="conveyData('co2Limitlow')"></b-form-spinbutton>
          </div>
        </div>
      </div>
      <div v-if="this.controllerType == '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.diff }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="co2Diff" min="0" max="1000" size="sm" step="10" @change="conveyData('co2Diff')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType == '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.startTime }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-timepicker v-if="this.controllerType == '제닉스'" v-model="co2StarttimeHm" size="sm" locale="en-US" class="mb-2 genix" minutes-step="60" @input="conveyTime"></b-form-timepicker>
          <b-form-timepicker v-else v-model="co2StarttimeHm" size="sm" locale="en-US" class="mb-2" @input="conveyTime"></b-form-timepicker>
        </div>
      </div>
      <div v-if="this.controllerType == '제닉스'" class="form-group row">
        <label class="col-form-label col-12">
          <span class="font-weight-normal">{{ i18n.operationTime }} : {{co2DurationHh}}{{i18n.hour}}</span>
        </label>
        <div class="col-12">
          <template>
            <div class="custom-range-slider">
              <b-form-input id="range-2" v-model="co2DurationHh" type="range" min="0" max="24" step="1" @change="conveyData('co2DurationHh')"></b-form-input>
              <div class=" d-flex justify-content-between">
                <span class="text">0</span>
                <span class="text">3</span>
                <span class="text">6</span>
                <span class="text">9</span>
                <span class="text">12</span>
                <span class="text">15</span>
                <span class="text">18</span>
                <span class="text">21</span>
                <span class="text">24</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType != '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.interval }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="co2Interval" min="0" max="10000" size="sm" @change="conveyData('co2Interval')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType != '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.duration }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="co2Duration" min="0" max="10000" size="sm" @change="conveyData('co2Duration')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <div v-if="this.controllerType != '코모로' && this.controllerType != '제닉스'" class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.repeatCount }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="co2Count" min="0" max="10000" size="sm" @change="conveyData('co2Count')"></b-form-spinbutton>
        </div>
      </div>
        <!-- <div class="col-12 col-lg-6">
          <b-form-timepicker v-model="co2StartTime" size="sm" locale="en-gb" class="mb-2" :hour12="false" @input="conveyTime" @context="onContext"></b-form-timepicker>
        </div> -->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import {syncObj3} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting3",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  watch: {
    effectValue(){
      if(this.elementObj) syncObj3(this.elementObj, this);
      if(this.elementObj) {
        this.co2DurationHh = this.elementObj.co2DurationHh
        if(this.elementObj.co2StarttimeHh !== undefined || this.elementObj.co2StarttimeMm !== undefined) {
          this.co2StarttimeHm = [(this.elementObj.co2StarttimeHh || '00'), (this.elementObj.co2StarttimeMm || '00'), '00'].join(':');
        }else{
          this.co2StarttimeHm = '';
        }
      }
    }
  },
  data() {
    return {
      co2Value : 0,
      co2Diff: 0,
      co2Limithigh : 0,
      co2Limitlow : 0,
      co2Interval : 0,
      co2Duration : 0,
      co2Count : 0,
      co2Min: 0,
      co2Max: 0,
      co2StarttimeHm : '',
      co2DurationHh : 0,
      // co2StartTime: '',
      i18n: {
        management: i18n.t('cultivationEnvControlSetting.management'),
        abnormalalert: i18n.t('cultivationEnvControlSetting.abnormalalert'),
        CO2: i18n.t('cultivationEnvControlSetting.CO2'),
        target: i18n.t('cultivationEnvControlSetting.target'),
        diff: i18n.t('cultivationEnvControlSetting.diff'),
        min: i18n.t('cultivationEnvControlSetting.min'),
        max: i18n.t('cultivationEnvControlSetting.max'),
        limitLow: i18n.t('cultivationEnvControlSetting.limitLow'),
        interval: i18n.t('cultivationEnvControlSetting.interval'),
        duration: i18n.t('cultivationEnvControlSetting.duration'),
        repeatCount: i18n.t('cultivationEnvControlSetting.repeatCount'),
        startTime: i18n.t('cultivationEnvControlSetting.startTime'),
        operationTime: i18n.t('cultivationEnvControlSetting.operationTime'),
        hour: i18n.t('cultivationEnvControlSetting.hour'),
        min2: i18n.t('cultivationEnvControlSetting.min2'),
        max2: i18n.t('cultivationEnvControlSetting.max2'),
      },
      context: null,
    };
  },
  computed: {
    isOperatingCorrectly() {
      if (this.elementObj.currentValue > this.co2Max || this.elementObj.currentValue < this.co2Min) {
        return {
          color: 'red'
        }
      }

      return {
        color: 'green'
      }
    }
  },
  methods: {
     conveyTime(){
      if(this.co2StarttimeHm){
        const starttimeList = this.co2StarttimeHm.split(':');
        if(starttimeList && starttimeList.length === 3) {
          this.mutateValue('co2', 'co2StarttimeHh', starttimeList[0]);
          this.mutateValue('co2', 'co2StarttimeMm', starttimeList[1]);
        }
      }
    },
    conveyData(key){
      this.mutateValue('co2', key, this[key]);
    },
    // onContext(ctx) {
    //   this.context = ctx;
    // }
  }
};
</script>
